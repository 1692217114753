@charset "UTF-8";
/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #FF1D3f;
  --ion-color-primary-rgb: 255,29,63;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #e01a37;
  --ion-color-primary-tint: #ff3452;
  --ion-color-secondary: #fddafe;
  --ion-color-secondary-rgb: 253,218,254;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #dfc0e0;
  --ion-color-secondary-tint: #fddefe;
  --ion-color-tertiary: #d2ecd9;
  --ion-color-tertiary-rgb: 210, 236, 217;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #b9d0bf;
  --ion-color-tertiary-tint: #d7eedd;
  --ion-color-success: #d2ecd9;
  --ion-color-success-rgb: 210, 236, 217;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #b9d0bf;
  --ion-color-success-tint: #d7eedd;
  --ion-color-warning: #fdcb6e;
  --ion-color-warning-rgb: 253, 203, 110;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #dfb361;
  --ion-color-warning-tint: #fdd07d;
  --ion-color-danger: #ff7675;
  --ion-color-danger-rgb: 255, 118, 117;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e06867;
  --ion-color-danger-tint: #ff8483;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --c-input-background: #ffffff;
  --c-input-color: #000000;
  --white: #ffffff;
  --black: #000000;
  --ion-statusbar-padding: 20px;
  --ion-safe-area-top: 10px;
  --ion-safe-area-bottom: 10px;
  --ion-padding: 20px;
}
@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */
  body {
    --c-input-background: #222428;
    --white: #222428;
    --c-input-color: #ffffff;
    --ion-color-primary: #f4f5f8;
    --ion-color-primary-rgb: 244, 245, 248;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #d7d8da;
    --ion-color-primary-tint: #f5f6f9;
    --ion-color-danger: #ff7675;
    --ion-color-danger-rgb: 255, 118, 117;
    --ion-color-danger-contrast: #000000;
    --ion-color-danger-contrast-rgb: 0, 0, 0;
    --ion-color-danger-shade: #e06867;
    --ion-color-danger-tint: #ff8483;
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
    --black: #ffffff;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */
  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-toolbar-background: #0d0d0d;
    --ion-item-background: #000000;
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */
  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-border-color: #222222;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    --ion-item-background: #1e1e1e;
    --ion-toolbar-background: #1f1f1f;
    --ion-tab-bar-background: #1f1f1f;
  }

  ion-title.title-large {
    --color: white;
  }
}
@font-face {
  font-family: "SourceSansPro";
  src: url('SourceSansPro-Black.d286fd54526d9eff4dac.ttf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "SourceSansPro";
  src: url('SourceSansPro-BlackItalic.2be8cc4aa79665218314.ttf');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "SourceSansPro";
  src: url('SourceSansPro-Bold.12e6acd2589d00c9d0aa.ttf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "SourceSansPro";
  src: url('SourceSansPro-BoldItalic.7c4f9b00300acb82f0f6.ttf');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "SourceSansPro";
  src: url('SourceSansPro-SemiBold.43cc81b496222dc9ce3c.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "SourceSansPro";
  src: url('SourceSansPro-SemiBoldItalic.c30987e28fc21daba1ae.ttf');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "SourceSansPro";
  src: url('SourceSansPro-Regular.efa76f8326aa5cee3bdd.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "SourceSansPro";
  src: url('SourceSansPro-Italic.3c01996d38a55834ddcb.ttf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "SourceSansPro";
  src: url('SourceSansPro-Light.201a905099bde5cc4079.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "SourceSansPro";
  src: url('SourceSansPro-LightItalic.e48afb32b46800476311.ttf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "SourceSansPro";
  src: url('SourceSansPro-ExtraLight.f9819ce5f7b3007625b6.ttf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "SourceSansPro";
  src: url('SourceSansPro-ExtraLightItalic.96c0d4b43501484a8e72.ttf');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: url('WorkSans-Black.f273c63948bbbec11104.ttf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Work Sans";
  src: url('WorkSans-BlackItalic.ba4ae43291f32d5f5224.ttf');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Work Sans";
  src: url('WorkSans-Bold.98df8dcb3955fd98b164.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Work Sans";
  src: url('WorkSans-BoldItalic.b4d2b6534a525810cbba.ttf');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: url('WorkSans-SemiBold.8cad3c8f3d74ece0ed3c.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: url('WorkSans-SemiBoldItalic.2bf7182a640c64476fa7.ttf');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: url('WorkSans-Regular.b13bc1b6c25b336a3323.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Work Sans";
  src: url('WorkSans-Italic.11e7df9be13c85634a12.ttf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: url('WorkSans-Light.9576eabbbdcb6e7bd822.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: url('WorkSans-LightItalic.5105c5e1ba2a7f94deb9.ttf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: url('WorkSans-ExtraLight.003c0b8dab52abb3c079.ttf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: url('WorkSans-ExtraLightItalic.af558e63ac8da474844a.ttf');
  font-weight: 200;
  font-style: italic;
}
:root {
  --ion-background-color: #ffffff;
  --ion-font-family: "Work Sans", sans-serif;
  --border-width: 0;
  --c-border-radius: 25px;
  --c-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  --c-box-shadow-bottom: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
  --c-box-shadow-right: 20px 0px 20px -5px rgba(0, 0, 0, 0.1);
}
:host {
  box-shadow: var(--c-box-shadow);
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
* {
  transition: all ease-in-out 100ms;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  /* Internet Explorer/Edge */
  user-select: none;
  touch-action: manipulation;
}
h1 {
  margin-top: 0 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
}
i {
  font-style: normal;
}
.breakable {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.hideDesktop {
  display: none;
}
.hidden {
  display: none !important;
}
*:focus {
  outline: none;
}
ion-avatar img {
  height: -webkit-fill-available !important;
}
@media (max-width: 768px) {
  .hideMobile {
    display: none;
  }

  .hideDesktop {
    display: block;
  }
}
ion-content {
  --padding-start: 10px;
  --padding-end: 10px;
}
.menu {
  width: 90px;
  position: fixed;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  left: 0;
  top: 0;
}
.content {
  left: 100px;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
  height: auto;
  padding: 0 20px;
  position: absolute;
  overflow: hidden;
}
.space {
  display: none;
}
.sidebar-container {
  text-align: center;
  background: var(--ion-background-color);
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}
.sidebar-container .menu-container {
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: space-around;
}
.sidebar-container .menu-container div i {
  color: var(--ion-color-dark);
  font-size: 1em;
}
.sidebar-container .menu-container div p {
  opacity: 0;
  color: var(--ion-color-dark);
  font-size: 8pt;
  font-weight: 400;
  margin-bottom: 0;
}
.sidebar-container .menu-container .active.menu-item,
.sidebar-container .menu-container div:hover.menu-item {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
  padding: 20px;
  margin: 5px;
  border-radius: var(--c-border-radius);
  box-shadow: var(--c-box-shadow);
}
.sidebar-container .menu-container .active p,
.sidebar-container .menu-container .active i,
.sidebar-container .menu-container div:hover p,
.sidebar-container .menu-container div:hover i {
  opacity: 1;
  color: var(--ion-color-secondary-contrast);
}
@media (max-width: 768px) {
  .content {
    padding: 0 10px 10px;
    left: 0;
  }

  ion-content {
    --padding-bottom: 85px ;
  }

  .menu {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
  }

  .space {
    display: block;
    height: 85px;
  }

  .sidebar-container {
    width: 100%;
    flex-direction: row;
  }
  .sidebar-container .menu-container {
    width: 100%;
    height: auto;
    flex-direction: row;
  }
  .sidebar-container .menu-container .active.menu-item,
.sidebar-container .menu-container div:hover.menu-item {
    background-color: transparent;
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
  .sidebar-container .menu-container .active.menu-item *,
.sidebar-container .menu-container div:hover.menu-item * {
    color: var(--ion-color-dark);
  }
}
ion-card,
.pac-container,
.tooltip .tooltiptext {
  margin: 10px 0;
  background: var(--white) !important;
  overflow: hidden;
  border-radius: var(--c-border-radius);
  box-shadow: var(--c-box-shadow);
  color: var(--ion-text-color);
  background-color: var(--ion-color-step-50);
}
ion-card.user,
.pac-container.user,
.tooltip .tooltiptext.user {
  padding: 0;
  margin: 10px 0;
}
ion-card.secondary,
.pac-container.secondary,
.tooltip .tooltiptext.secondary {
  background-color: var(--ion-color-secondary) !important;
  color: var(--ion-color-secondary-contrast) !important;
}
ion-card.secondary *,
.pac-container.secondary *,
.tooltip .tooltiptext.secondary * {
  color: var(--ion-color-secondary-contrast) !important;
}
ion-card.tertiary,
.pac-container.tertiary,
.tooltip .tooltiptext.tertiary {
  background-color: var(--ion-color-tertiary) !important;
  color: var(--ion-color-tertiary-contrast) !important;
}
ion-card.tertiary *,
.pac-container.tertiary *,
.tooltip .tooltiptext.tertiary * {
  color: var(--ion-color-tertiary-contrast) !important;
}
ion-card ion-card-header,
.pac-container ion-card-header,
.tooltip .tooltiptext ion-card-header {
  padding-bottom: 0;
}
ion-card ion-card-title,
.pac-container ion-card-title,
.tooltip .tooltiptext ion-card-title {
  font-weight: bold;
}
ion-card ion-card-content,
.pac-container ion-card-content,
.tooltip .tooltiptext ion-card-content {
  padding-bottom: 0 !important;
}
ion-card.flex {
  display: grid;
  grid-template-columns: auto 125px;
  grid-template-rows: auto;
  grid-template-areas: "header buttons" "content buttons";
}
ion-card.flex ion-card-header {
  grid-area: header;
}
ion-card.flex ion-card-content {
  grid-area: content;
}
ion-card.flex.tertiary ion-card-buttons::before {
  background: var(--ion-color-tertiary) !important;
}
ion-card.flex ion-card-buttons::before {
  content: " ";
  background: var(--white) !important;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 25px;
  border-radius: 0 25px 25px 0;
  box-shadow: var(--c-box-shadow-right);
}
ion-card.flex ion-card-buttons {
  position: relative;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: var(--ion-color-secondary) !important;
  color: var(--ion-color-secondary-contrast) !important;
  padding-left: 30px;
  padding-right: 5px;
  margin-left: -25px;
  grid-area: buttons;
  z-index: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
ion-card.flex ion-card-buttons button {
  background: transparent;
  box-shadow: none;
  text-transform: none;
}
ion-card.flex ion-card-buttons i {
  font-size: 16pt;
  margin-top: 10px;
}
ion-card.flex ion-card-buttons h6 {
  margin-top: 8px;
}
@media (max-width: 768px) {
  ion-card.flex {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-template-areas: "header" "content" "buttons";
  }

  ion-card-content {
    background: var(--white) !important;
    border-radius: var(--c-border-radius);
    box-shadow: var(--c-box-shadow-bottom);
  }

  ion-card-buttons::before {
    display: none;
  }

  ion-card-buttons {
    width: auto !important;
    flex-direction: row !important;
    padding-top: 35px;
    padding-bottom: 5px;
    margin-top: -25px;
    z-index: -1 !important;
  }
  ion-card-buttons h6 {
    font-size: 10pt;
  }
}
ion-c-buttons {
  display: flex;
  z-index: 1;
}
ion-c-buttons.line-break ion-button {
  height: auto !important;
}
ion-c-buttons.line-break ion-button i {
  margin-top: 10px;
}
ion-c-buttons.line-break ion-button h6 {
  margin-top: 4px;
}
ion-c-buttons ion-button {
  --box-shadow: var(--c-box-shadow-bottom);
  --border-radius: var(--c-border-radius);
  flex: 1;
  border-radius: var(--c-border-radius);
  text-transform: none;
  margin: 0 10px;
  background-color: var(--ion-color-secondary) !important;
  --background: var(--ion-color-secondary) !important;
  color: var(--ion-color-secondary-contrast) !important;
}
ion-c-buttons ion-button button * {
  display: block;
}
ion-c-buttons ion-button button:hover {
  color: var(--ion-color-secondary-contrast) !important;
}
ion-c-buttons ion-button i {
  font-size: 16pt;
  padding-right: 8px;
}
@media (max-width: 768px) {
  ion-content.buttons {
    --background: transparent;
  }

  ion-content {
    --padding-bottom: 155px;
  }

  ion-c-buttons {
    flex-direction: row;
    justify-content: space-around;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 75px;
    padding: 10px 0;
    margin: 0 10px;
    background-color: var(--ion-color-secondary) !important;
    color: var(--ion-color-secondary-contrast) !important;
    border-radius: 25px;
    box-shadow: var(--c-box-shadow);
  }
  ion-c-buttons ion-button button {
    background: transparent;
    box-shadow: none;
    --box-shadow: none;
    color: var(--ion-color-secondary-contrast) !important;
    text-transform: none;
  }
  ion-c-buttons ion-button button:hover {
    color: var(--ion-color-secondary-contrast) !important;
  }
  ion-c-buttons ion-button i {
    font-size: 16pt;
  }
}
table tr {
  height: 40px;
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 340px;
  text-align: left;
  padding: 15px;
  bottom: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  z-index: 10;
}
.tooltip .tooltiptext.bottom {
  top: 90%;
  left: 50%;
  margin-left: -170px;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.progressBar {
  list-style-type: none;
  float: left;
  position: relative;
  text-align: center;
  margin: 0 auto;
  z-index: 1;
}
.progressBar:after {
  content: "";
  position: absolute;
  width: 450%;
  height: 1px;
  background-color: var(--ion-color-dark);
  top: 50%;
  left: 50%;
  z-index: -1;
}
.progressBar:last-of-type:after {
  content: none;
}
.progressBar.disabled::after {
  background-color: var(--ion-color-light-shade);
}
.progressBarStatus {
  list-style-type: none;
  float: left;
  position: relative;
  text-align: center;
  margin: 0 auto;
  z-index: 1;
}
.progressBarStatus.disabled::before {
  content: "";
  position: absolute;
  width: 150%;
  height: 2px;
  background-color: #ddd;
  bottom: 0;
  left: -50%;
  z-index: -1;
}
.progressBarStatus.disabled {
  color: #ddd;
}
.progressBarStatusContainer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #1e9600;
  background: linear-gradient(to right, #f8b88b, #faf884, #baed91);
  bottom: 0;
  left: 0;
}
@media (max-width: 768px) {
  .progressBarStatus p {
    display: none;
  }

  .progressBarStatusContainer:before {
    width: calc(100% - 50px);
    left: 25px;
  }
}
.progress {
  background: rgba(255, 255, 255, 0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  margin: 0 5px;
  display: flex;
  height: 10px;
  width: 100%;
  position: absolute;
  top: 10px;
}
.progress-value {
  box-shadow: 0 10px 40px -10px #d6d3d3;
  border-radius: 100px;
  background: #fff;
  height: 10px;
  width: 0;
}
.progressCounter {
  position: absolute;
  top: 10px;
  right: 10px;
}
.progressCounter h1 {
  font-size: 14pt;
  margin: 0;
}
.progressCounter p {
  font-size: 10pt;
  margin: 0;
}
.ngx-slider .ngx-slider-bar-wrapper {
  width: auto;
  left: 15px;
  right: 15px;
}
.slider * {
  color: var(--ion-color-dark) !important;
}
.slider .ngx-slider .ngx-slider-bar {
  background: var(--ion-color-primary);
  height: 2px;
}
.slider .ngx-slider .ngx-slider-selection {
  background: var(--ion-color-primary);
}
.slider .ngx-slider .ngx-slider-pointer {
  background-color: var(--ion-color-primary);
}
.slider .ngx-slider .ngx-slider-tick-legend {
  max-width: 100px;
}
.slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}
.slider .ngx-slider .ngx-slider-bubble {
  bottom: 14px;
}
.slider .ngx-slider .ngx-slider-limit {
  font-weight: bold;
}
.slider .slider * {
  color: var(--ion-color-dark) !important;
}
.slider .slider .ngx-slider .ngx-slider-bar {
  background: var(--ion-color-primary);
  height: 2px;
}
.slider .slider .ngx-slider .ngx-slider-selection {
  background: var(--ion-color-primary);
}
.slider .slider .ngx-slider .ngx-slider-pointer {
  background-color: var(--ion-color-primary);
}
.slider .slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}
.slider .slider .ngx-slider .ngx-slider-bubble {
  bottom: 14px;
}
.slider .slider .ngx-slider .ngx-slider-limit {
  font-weight: bold;
}
.slider .slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: var(--ion-color-primary);
}
@media (max-width: 568px) {
  .slider .ngx-slider.vertical {
    height: 300px !important;
  }
  .slider .ngx-slider.vertical .ngx-slider-bar-wrapper {
    width: auto !important;
  }
  .slider .ngx-slider .ngx-slider-bar-wrapper {
    width: auto;
    left: 0;
    right: auto;
  }
}
.slider .swiper-slide {
  flex-flow: wrap;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}
.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.align-items-center {
  align-items: center !important;
}
.text-center {
  text-align: center !important;
}
.oi {
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
}
.oi-chevron-left:before {
  content: "";
}
.oi-chevron-right:before {
  content: "";
}
.oi-chevron-top:before {
  content: "";
}
ion-modal {
  --border-radius: var(--c-border-radius) !important;
}
ion-modal .ion-page {
  padding: 10px;
}
.modal60 {
  --height: 60%;
}
ion-button {
  --border-radius: var(--c-border-radius);
  --background-color: var(--ion-color-secondary) !important;
  --background-hover: var(--ion-color-secondary-shade) !important;
  --background-focused-opacity: var(--ion-color-secondary-shade) !important;
  margin: 10px 0;
  --box-shadow: var(--c-box-shadow);
}
ion-button.disabled {
  --background: var(--ion-color-medium) !important;
}
ion-button h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  ion-button.small {
    font-size: 0.7em;
    --padding-start: 5px;
    --padding-end: 5px;
  }
}
ion-button div {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
ion-input {
  --border-radius: var(--c-border-radius);
}
ion-input input {
  -webkit-user-select: text !important;
  padding: 15px 20px !important;
  background: var(--c-input-background) !important;
  box-shadow: var(--c-box-shadow);
  margin: 0 0 0 5px;
}
ion-textarea {
  --border-radius: var(--c-border-radius);
}
ion-textarea textarea {
  -webkit-user-select: text !important;
  padding: 15px 20px !important;
  background: var(--c-input-background) !important;
  border-radius: 25px !important;
  box-shadow: var(--c-box-shadow);
  margin: 0 0 0 5px;
}
ion-checkbox {
  margin: 0;
  width: 30px;
  height: 30px;
  top: 10px;
}
ion-range {
  padding: 2.5px 20px !important;
}
.ion-input,
ion-range {
  padding: 15px 20px;
  background: var(--c-input-background) !important;
  border-radius: var(--c-border-radius);
  box-shadow: var(--c-box-shadow);
  border: 0;
  width: 100%;
}
.range-pin {
  background: var(--ion-primary-contrast) !important;
  box-shadow: var(--c-box-shadow) !important;
  border-radius: var(--c-border-radius);
}
ion-item.input {
  border-radius: var(--c-border-radius);
  --border-radius: var(--c-border-radius);
  box-shadow: var(--c-box-shadow);
  --background: var(--c-input-background);
  --padding-end: 0;
}
ion-item ion-input {
  border-width: 0 !important;
}
ion-item ion-input > input {
  box-shadow: none !important;
}
ion-item ng-select {
  width: 70%;
  box-shadow: none !important;
}
ion-item.margin {
  margin: 20px 0;
}
ion-item.dateInput {
  --padding-start: 0;
  --inner-padding-end: 0;
}
ion-item.dateInput dl-date-time-picker {
  width: 100%;
}
input[type=radio] {
  width: auto;
  margin: 0;
  -webkit-appearance: none;
  border: 1px solid #cacece;
  padding: 9px !important;
  border-radius: 20px !important;
}
input[type=radio]:checked {
  background-color: black;
  border: 1px solid #adb8c0;
  color: white;
}
.ng-dropdown-panel {
  padding: 15px 20px;
  overflow: hidden;
  border: 0 !important;
  width: 100%;
  border-radius: var(--c-border-radius);
  box-shadow: var(--c-box-shadow);
  background-color: var(--c-input-background) !important;
  color: var(--c-input-color);
  transition: height 2s;
  -webkit-transition: height 2s;
}
.ng-dropdown-panel.fullHeight .ng-dropdown-panel-items {
  max-height: 60vh !important;
}
.ng-option {
  background-color: var(--c-input-background) !important;
  color: var(--c-input-color) !important;
}
.ng-select {
  padding: 15px 20px;
  background-color: var(--c-input-background);
  color: var(--c-input-color);
  border: 0;
  border-radius: var(--c-border-radius);
  box-shadow: var(--c-box-shadow);
}
.ng-select.ng-select-single .ng-select-container {
  height: auto !important;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0 !important;
}
.ng-select .ng-select-container {
  min-height: 0px !important;
  border: 0 !important;
  background-color: var(--c-input-background) !important;
  color: var(--c-input-color) !important;
}
.ng-select.ng-select-single .ng-select-container {
  height: auto !important;
}
ui-select.flex > .selectContainer {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.dl-abdtp-date-button,
.dl-abdtp-view-label,
.dl-abdtp-left-button,
.dl-abdtp-right-button {
  color: var(--c-input-color) !important;
  background-color: transparent !important;
}
.dl-abdtp-date-button:hover,
.dl-abdtp-left-button:hover,
.dl-abdtp-right-button:hover,
.dl-abdtp-up-button:hover {
  background: rgba(var(--ion-color-secondary-rgb), 0.4) !important;
}
.dl-abdtp-active:focus,
.dl-abdtp-date-button:focus,
.dl-abdtp-left-button:focus,
.dl-abdtp-right-button:focus,
.dl-abdtp-up-button:focus,
.dl-abdtp-view-label:focus {
  background: rgba(var(--ion-color-secondary-rgb), 0.4) !important;
}
.dl-abdtp-selected,
.dl-abdtp-selected:hover {
  color: var(--ion-color-secondary-contrast) !important;
  background-color: var(--ion-color-secondary) !important;
}
.dl-abdtp-now {
  border: 2px solid rgba(var(--ion-color-secondary-rgb), 0.8) !important;
}
.signIn {
  background-color: var(--black);
  border-radius: var(--c-border-radius);
  margin: 10px;
  color: var(--white);
}
.signIn i {
  padding: 20px;
  font-size: 2em;
}
.signIn.facebook {
  background-color: #3b5998;
  color: white !important;
}
.signIn.google {
  background-color: #ee501e;
  color: white !important;
}
.signIn.microsoft {
  background-color: #7ab401;
  color: white !important;
}
.signIn:hover {
  transform: scale(1.1);
}
.signInContainer {
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 767.98px) {
  .signIn i {
    padding: 15px;
    font-size: 1.5em;
  }
}